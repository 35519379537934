import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import styled from 'styled-components';
import type { CommonBasicTextProps } from '~/components/common/text/commonBasicTextCss';
import { commonBasicTextCss } from '~/components/common/text/commonBasicTextCss';

interface Props extends ComponentPropsWithoutRef<'p'>, CommonBasicTextProps {
  children: ReactNode;
}

const Container = styled.p<CommonBasicTextProps>`
  ${(props) => commonBasicTextCss(props)}
`;

export const BasicParagraph = ({
  size,
  pcSize,
  colorStyle = 'main',
  weight = 'normal',
  letterSpacing,
  lineHeight,
  fontFamily,
  children,
  className,
  truncateLine,
  fontStyle,
  textAlign,
  wordBreak,
  whiteSpace,
}: Props) => {
  return (
    <Container
      className={className}
      size={size}
      pcSize={pcSize || size}
      colorStyle={colorStyle}
      weight={weight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      fontFamily={fontFamily}
      truncateLine={truncateLine}
      fontStyle={fontStyle}
      textAlign={textAlign}
      wordBreak={wordBreak}
      whiteSpace={whiteSpace}
    >
      {children}
    </Container>
  );
};
