import { DeviceTypes } from '~/interfaces/enums/common';

const SizeUnit = <const>{
  Px: 'Px',
};
type SizeUnit = (typeof SizeUnit)[keyof typeof SizeUnit];

export const DESIGN_WIDTH = {
  [DeviceTypes.PC]: 2560,
  [DeviceTypes.Tablet]: 1668,
  [DeviceTypes.SP]: 750,
};

export const vw = (val: number) => {
  return `calc(${val} * var(--vw, 1vw))`;
};

export const vh = (val: number) => {
  return `calc(${val} * var(--vh, 1vh))`;
};

const _px = (val: number) => {
  return `${val / 2}px`;
};

export const px = (...args: number[]) => {
  return args.map((v) => _px(v)).join(' ');
};

const pxToVw = (px: number, deviceType: DeviceTypes) => {
  const val = (px / DESIGN_WIDTH[deviceType]) * 100;
  return vw(val);
};

export const pxVw = {
  [DeviceTypes.PC]: (...args: number[]) => args.map((v) => pxToVw(v, DeviceTypes.PC)).join(' '),
  [DeviceTypes.Tablet]: (...args: number[]) =>
    args.map((v) => pxToVw(v, DeviceTypes.Tablet)).join(' '),
  [DeviceTypes.SP]: (...args: number[]) => args.map((v) => pxToVw(v, DeviceTypes.SP)).join(' '),
};

export const fontFamilies = {
  asics: `'asicskids', sans-serif`,
  sen: `'Sen', sans-serif`,
  notoSans: `'Noto Sans JP', sans-serif`,
  sansSerif: `'Hiragino Kaku Gothic', 'メイリオ', sans-serif`,
} as const;
export type FontFamilyKeys = keyof typeof fontFamilies;
export type FontFamilyValues = (typeof fontFamilies)[FontFamilyKeys];

/**
 * @param fontSize {number} figmaの値をnumberとして渡す
 * @param fontStyleValue {number | string} figmaのCSSフォントスタイル値を渡す。
 * @returns pxの場合はnumberとして渡し、内部でemに変換して返す。'normal'などstringで渡ってきた場合はそのまま返す。
 */

export const calculateFontStyleValue = (fontSize: number, fontStyleValue: number | string) => {
  const isNumber = typeof fontStyleValue === 'number';
  return isNumber ? `${Number(fontStyleValue) / fontSize}em` : fontStyleValue;
};

/**
 * @param unitType {DeviceTypes | SizeUnit} デバイスや単位を指定する
 * @param fontSize {number} figmaの値をnumberとして渡す
 * @param letterSpacing {number | string} figmaのそのまま渡す。pxの値を渡したい場合はnumberとして渡し、内部でemに変換する。
 * @param lineHeight: {number | string} figmaのそのまま渡す。pxの値を渡したい場合はnumberとして渡し、内部でemに変換する
 * @param sizeAdjust: {number} props.theme.sizeAdjust[0] のようにローカライズ時の補正値をthemeから渡す
 * @param lineHeightFix: {boolean} props.theme.lineHeightFix
 * @returns
 */
export const font = (
  unitType: DeviceTypes | SizeUnit,
  fontSize: Parameters<typeof calculateFontStyleValue>[0],
  letterSpacing?: Parameters<typeof calculateFontStyleValue>[1],
  lineHeight?: Parameters<typeof calculateFontStyleValue>[1],
  sizeAdjust?: number,
  lineHeightFix?: boolean,
) => {
  const _fontSize = fontSize + (sizeAdjust || 0);
  const _lineHeight = lineHeightFix ? '140%' : lineHeight;

  return `
    font-size: ${unitType === SizeUnit.Px ? px(_fontSize) : pxVw[unitType](_fontSize)};
    letter-spacing: ${calculateFontStyleValue(_fontSize, letterSpacing || 'normal')};
    line-height: ${calculateFontStyleValue(_fontSize, _lineHeight || _fontSize)};
  `;
};

export const ReadImageCSS = (imgSrc: string) => {
  return `
    background-image: url(${imgSrc});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  `;
};

export const windowHeight = () => {
  return `${window.innerHeight}px`;
};

/**
 * theme.text.sizeAdjust[index]に指定するべきindexをfontSizeから計算し、取得する
 * @param fontSize {number} figma上のフォントサイズ
 * @param type {'text' | 'button' | 'tag'} テキストの種類
 */
type TextType = 'text' | 'button' | 'tag';

export const getIndexOfSizeAdjust = (fontSize: number, type?: TextType): number => {
  let index = 0;
  if (type === 'button') {
    if (fontSize < 20) {
      index = 0;
    } else if (fontSize <= 28) {
      index = 1;
    } else {
      index = 4;
    }
  } else if (type === 'tag') {
    if (fontSize < 20) {
      index = 0;
    } else if (fontSize < 28) {
      index = 1;
    } else {
      index = 2;
    }
  } else {
    if (fontSize < 20) {
      index = 0;
    } else if (fontSize < 26) {
      index = 1;
    } else if (fontSize < 32) {
      index = 2;
    } else {
      index = 3;
    }
  }

  return index;
};
