import { atom } from 'recoil';
import { RecoilAtomKeys } from '~/interfaces/enums/recoilKeys';
import type { ThemeType } from '~/interfaces/enums/theme';
import { persistAtom } from '~/recoil/persist';

export const themeAtom = atom<ThemeType>({
  key: RecoilAtomKeys.Theme,
  default: 'default',
  effects_UNSTABLE: [persistAtom],
});
