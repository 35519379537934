const basePath = '/assets';

const getPath = (dir: string, path: string) => `${basePath}/${dir}${path}`;

export const path = {
  image: (path: string) => getPath('img', path),
  sound: (path: string) => getPath('sounds', path),
  video: (path: string) => getPath('videos', path),
  pdf: (path: string) => getPath('pdf', path),
};
