import 'modern-css-reset/dist/reset.min.css';
import '~/styles/globals.scss';
import { GoogleTagManager } from '@next/third-parties/google';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { UAParser } from 'ua-parser-js';
import { PlaneLayout } from '~/components/common/PlaneLayout';
import { ErrorFallback } from '~/components/functional/ErrorFallback';
import { Loading } from '~/components/loading/Loading';
import { PcPage } from '~/components/pc/PcPage';
import { LoadingContextProvider } from '~/context/LoadingContext';
import { ModalContextProvider } from '~/context/ModalContext';
import { useCustomRouting } from '~/hooks/useCustomRouting';
import { useEnv } from '~/hooks/useEnv';
import { useLocale } from '~/hooks/useLocale';
import type { ThemeType } from '~/interfaces/enums/theme';
import { Theme } from '~/interfaces/enums/theme';
import nextI18NextConfig from '~/next-i18next.config';
import { useThemeAction } from '~/recoil/theme/action';
import { useThemeSelector } from '~/recoil/theme/selector';
import { styles } from '~/styles/theme';
import { colors } from '~/styles/ts/colors';

interface MyThemeProviderProps {
  children: ReactNode;
}

const MyThemeProvider = ({ children }: MyThemeProviderProps) => {
  const { theme } = useThemeSelector();
  const { setTheme } = useThemeAction();
  const { locale } = useLocale();

  useEffect(() => {
    if (theme !== locale) {
      const newTheme: ThemeType = Theme.find((t) => t === locale) || 'default';
      setTheme(newTheme);
    }
  }, [locale]);

  return <ThemeProvider theme={styles[theme]}>{children}</ThemeProvider>;
};

function MyApp({ Component, pageProps }: AppProps) {
  const [canRender, setCanRender] = useState(process.env.NODE_ENV !== 'development');
  const { env, isProd } = useEnv();
  const router = useRouter();
  useCustomRouting();

  useEffect(() => {
    if (env && !isProd) {
      // production以外の環境は環境名をconsoleに出しておきたい
      // eslint-disable-next-line no-console
      console.warn(`=== ${env} ===`);
    }
  }, [env, isProd]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const MockServer = () =>
        import('~/mocks/msw').then(async (mo) => {
          await mo.startMock(location.href.includes('3001'));
          setCanRender(true);
        });
      (async () => {
        await MockServer();
      })();
    } else {
      setCanRender(true);
    }
  }, []);

  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const parser = new UAParser();
    const device = parser.getDevice().type;
    setIsMobile(device === UAParser.DEVICE.MOBILE || device === UAParser.DEVICE.TABLET);
  }, []);

  const RenderComponent = useMemo(() => {
    if (isMobile === undefined) return <></>;
    const isFaq = router.pathname.includes('/faq');
    if (!isMobile && !isFaq) return <PcPage />;
    return <Component {...pageProps} />;
  }, [Component, isMobile, pageProps, router]);

  if (!canRender)
    return (
      <PlaneLayout>
        <Loading color={colors.asicsKidsNavy} />
      </PlaneLayout>
    );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <RecoilRoot>
        <MyThemeProvider>
          <LoadingContextProvider>
            <ModalContextProvider>
              {process.env.NEXT_PUBLIC_GTM_ID && (
                <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
              )}
              {RenderComponent}
            </ModalContextProvider>
          </LoadingContextProvider>
        </MyThemeProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
