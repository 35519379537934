import { useRouter } from 'next/router';
import { useMemo } from 'react';
import type { Languages, PageRegion } from '~/interfaces/common';

export const useLocale = () => {
  const { locale } = useRouter();

  const isEnglish = useMemo(() => {
    return ['en', 'ar'].some((v) => v === locale);
  }, [locale]);

  const isJapanese = useMemo(() => {
    return ['ja'].some((v) => v === locale);
  }, [locale]);

  const isArabic = useMemo(() => {
    return ['ar'].some((v) => v === locale);
  }, [locale]);

  const lang = useMemo<Languages>(() => {
    switch (locale) {
      case 'ja':
        return 'ja';
      case 'en':
        return 'en';
      case 'ar':
        return 'ar';
      default:
        return 'en';
    }
  }, [locale]);

  const regionCode = useMemo<PageRegion>(() => {
    return locale === 'ja' ? 'jp' : 'en';
  }, [locale]);

  return { locale, isEnglish, isJapanese, lang, regionCode, isArabic };
};
