export const RecoilAtomKeys = <const>{
  Theme: 'atom.theme',
  Status: 'atom.status',
  Advice: 'atom.advice',
  User: 'atom.user',
  Child: 'atom.child',
  ChildFirstLogin: 'atom.childFirstLogin',
  ChildIds: 'atom.child.ids',
  Product: 'atom.product',
  ProductRecommendedIds: 'atom.product.recommended.ids',
  ProductRecommendedChildId: 'atom.product.recommended.childId',
  Growth: 'atom.growth',
  Personal: 'atom.personal',
  Average: 'atom.average',
  Family: 'atom.family',
  Everyone: 'atom.everyone',
  Stage: 'atom.stage',
  Terms: 'atom.terms',
  TermsOptin: 'atom.terms.optin',
  Policy: 'atom.policy',
  Consents: 'atom.consents',
  Article: 'atom.article',
  ArticleIds: 'atom.article.ids',
  ArticleCursor: 'atom.article.cursor',
  ArticleNewArrivalDate: 'atom.article.NewArrivalDate',
  ArticleFilter: 'atom.article.filter',
  Achievement: 'atom.achievement',
  Event: 'atom.event',
  EventIds: 'atom.event.ids',
  EventsNewArrivalDate: 'atom.events.NewArrivalDate',
  Artwork: 'atom.artwork',
  ArtworkGalleryIds: 'atom.artwork.gallery.ids',
  NavigationFunction: 'atom.navigation.function',
};

export const RecoilSelectorKeys = <const>{
  StatusDisplayLength: 'selector.status.displayLength',
  StatusDisplayCircumference: 'selector.status.displayCircumference',
  Children: 'selector.children',
  Products: 'selector.products',
  GrowthMonth: 'selector.growth.month',
  GrowthMonthXaxis: 'selector.growth.month.xaxis',
  GrowthYear: 'selector.growth.year',
  GrowthYearXaxis: 'selector.growth.yaar.xaxis',
  GrowthAgeofMonthArray: 'selector.growth.ageofMonthArray',
  Personal: 'selector.compare.personal',
  Average: 'selector.compare.average',
  Family: 'selector.compare.family',
  Everyone: 'selector.compare.everyone',
  EveryoneChild: 'selector.compare.everyoneChild',
  Articles: 'selector.articles',
  ArticlesHasFilterChanged: 'selector.articles.hasFilterChanged',
  Events: 'selector.events',
  Gallery: 'selector.gallery',
};

export type RecoilAtomKeys = (typeof RecoilAtomKeys)[keyof typeof RecoilAtomKeys];
export type RecoilSelectorKeys = (typeof RecoilSelectorKeys)[keyof typeof RecoilSelectorKeys];
