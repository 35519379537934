import { useTranslation as useTranslationDefault } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import reactHtmlParser from 'react-html-parser';
import type { LocalizeKey } from '~/interfaces/i18n';

export const useTranslation = () => {
  const nsList = ['index'];

  const { t: tDefault, i18n } = useTranslationDefault(nsList);

  const ready = useMemo(() => !!tDefault, [tDefault]);

  const t = useCallback(
    (key: LocalizeKey, opts: { [key: string]: string | number | boolean } = {}) => {
      return tDefault ? reactHtmlParser(tDefault(key, opts)) : '';
    },
    [ready],
  );

  return { t, i18n, ready };
};
