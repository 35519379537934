const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  backend: {
    backendOptions: [
      {
        expirationTime: 60 * 1000,
        store: typeof window !== 'undefined' ? window.localStorage : null,
        defaultVersion: 'v0.4',
        prefix: 'ASN.i18n_',
      },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ],
    backends: typeof window !== 'undefined' ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    defaultLocale: 'default',
    locales: ['en', 'ja', 'ar', 'default'],
  },
  localeDetection: false,
  defaultNS: 'index',
  localeStructure: '{{lng}}/{{ns}}',
  reloadOnPrerender: true,
  use: typeof window !== 'undefined' ? [ChainedBackend] : [],
};
