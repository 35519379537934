const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';
const regionNameMap = isProd
  ? {
      jp: 'AJP',
      ae: 'AAF',
      bh: 'AAF',
      kw: 'AAF',
      om: 'AAF',
      qa: 'AAF',
      sa: 'AAF',
    }
  : ({
      jp: 'AJP', // デフォルト値
      // uk: 'AUK',
      // de: 'ADG',
      // fr: 'AFR',
      // it: 'AIT',
      // es: 'AIB',
      // au: 'AOP',
      ae: 'AAF',
      bh: 'AAF',
      kw: 'AAF',
      om: 'AAF',
      qa: 'AAF',
      sa: 'AAF',
    } as const);

export type ARegionName = (typeof regionNameMap)[keyof typeof regionNameMap];
type RegionCode = keyof typeof regionNameMap;

const isRegionCode = (v: unknown): v is RegionCode => {
  return typeof v === 'string' && Object.keys(regionNameMap).includes(v);
};

const isARegionName = (v: unknown): v is ARegionName => {
  const _rn: string[] = [...Object.values(regionNameMap)];
  return typeof v === 'string' && _rn.includes(v);
};

const hasRegionPath = (pathname: string) => {
  const firstPathSegment = pathname.split('/')[1];
  return isRegionCode(firstPathSegment);
};

const getARegionName = (region_code: string): ARegionName => {
  const _code = region_code?.toLowerCase();

  if (isRegionCode(_code)) {
    return regionNameMap[_code];
  }
  if (isARegionName(_code)) {
    return _code;
  }

  return regionNameMap['jp'];
};

const getRegionCodeFromPathName = (pathname: string) => {
  const firstPathSegment = pathname.split('/')[1];
  if (!isRegionCode(firstPathSegment)) return undefined;
  return firstPathSegment;
};

const normalizeRegionCode = (regionCode: string): RegionCode => {
  const _code = regionCode?.toLowerCase();
  if (isRegionCode(_code)) {
    return _code;
  }

  return 'jp';
};

export const regionService = {
  isRegionCode,
  isARegionName,
  getARegionName,
  getRegionCodeFromPathName,
  regionNameMap,
  hasRegionPath,
  normalizeRegionCode,
};
