import type { ThemeType } from '~/interfaces/enums/theme';
import { en } from '~/styles/theme/en';
import { ja } from '~/styles/theme/ja';
import type { ThemeStyle } from '~/styles/theme/type';

export const styles: { [P in ThemeType]: ThemeStyle } = {
  default: en,
  ja: ja,
  en: en,
};
