import { CommonImage } from '~/components/common/image/CommonImage';
import { BasicParagraph } from '~/components/common/text/BasicParagraph';
import styles from '~/components/pc/PcPage.module.scss';
import { useLocale } from '~/hooks/useLocale';
import { useTranslation } from '~/hooks/useTranslation';
import { path } from '~/utils/path';

export const PcPage = () => {
  const { t } = useTranslation();
  const { isEnglish } = useLocale();
  return (
    <div className={styles.container}>
      <h1 className={styles.logo}>
        <CommonImage
          src={path.image('/pc/logo.png')}
          width={510}
          height={146}
          objectFit="contain"
        />
      </h1>
      <div className={styles.content}>
        <BasicParagraph
          size={88}
          pcSize={88}
          lineHeight={1.5}
          weight={700}
          colorStyle="inversed"
          className={styles.copy}
        >
          {t('pc_page_copy')}
        </BasicParagraph>
        <div className={styles['qr-container']}>
          <CommonImage
            src={path.image('/pc/qr.png')}
            width={240}
            height={240}
            objectFit="contain"
          />
          <BasicParagraph
            size={isEnglish ? 44 : 36}
            pcSize={isEnglish ? 44 : 36}
            lineHeight={1.5}
            weight={700}
            className={styles['qr-text']}
            colorStyle="inversed"
          >
            {t('pc_page_txt')}
          </BasicParagraph>
        </div>
      </div>
    </div>
  );
};
