import { STORAGE_PREFIX } from '~/persistence/prefix';

const numberRegexPattern = /^[-]?[0-9]+(\.[0-9]+)?$/;

export class Persistence<T> {
  private _resource: string;

  constructor(resource: string) {
    this._resource = STORAGE_PREFIX + resource;
  }

  private _checkType(value: unknown): boolean {
    if (typeof value === 'undefined') return true;

    if (typeof value === 'number') {
      const test = [numberRegexPattern.test(value.toString())];
      return test.every((v) => v);
    }

    // string, objectに関しては確認できる方法がないのでtrueを返す
    // booleanは型が判明している時点でtrue/falseのどちらかが入っていることが確定しているのでtrueを返す
    return true;
  }

  public get value(): T | undefined {
    const _v = window.localStorage.getItem(this._resource);
    if (typeof _v !== 'string') return undefined;

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsedValue = JSON.parse(_v);

      if (this._checkType(parsedValue)) {
        return parsedValue as T;
      } else {
        throw new Error('Saved value type is not match T.');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (_e: any) {
      throw new Error(String(_e));
    }
  }

  public set(value: T): void {
    if (this._checkType(value)) {
      window.localStorage.setItem(this._resource, JSON.stringify(value));
    } else {
      throw new Error('Set value type does not match T.');
    }
  }

  public remove(): void {
    window.localStorage.removeItem(this._resource);
  }
}
