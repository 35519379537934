import type { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { clearAllPersist } from '~/persistence/clearAllPersist';
import { colors } from '~/styles/ts/colors';
import { fontFamilies } from '~/styles/ts/functions';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 480px;
  margin: 64px auto;

  & > pre {
    width: 100%;
    padding: 8px 16px;
    background: ${colors.gray_1};
  }
`;

const Title = styled.h2`
  font-family: ${fontFamilies.sen};
  font-weight: 700;
  text-align: center;
  font-size: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  margin: 32px;
  cursor: pointer;
  text-decoration: underline;
`;

const reset = () => {
  if (typeof window !== 'undefined') {
    if (window.confirm('端末内のデータを削除してよろしいですか？ ※アカウントは削除されません')) {
      clearAllPersist();
      location.href = '/';
    }
  }
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <Container>
    <Title>ERROR</Title>
    <p>Sorry. Something went wrong. </p>
    <ButtonContainer>
      <Button onClick={resetErrorBoundary}>Retry</Button>
      <Button onClick={reset}>Reset Local Data</Button>
    </ButtonContainer>
    <p>Detail: </p>
    <pre>{error.message}</pre>
  </Container>
);
