import { css } from 'styled-components';
import type { FontThemeStyle } from '~/styles/theme/type';
import type { TextColorKeys } from '~/styles/ts/colors';
import { colors } from '~/styles/ts/colors';
import { fontFamilies, px, pxVw } from '~/styles/ts/functions';
import { media } from '~/styles/ts/media';

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type Weight = 'normal' | 'bold' | FontWeight;
type FontStyle = 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
type TextAlign = 'left' | 'right' | 'center' | 'justify';
type WordBreak = 'normal' | 'break-all' | 'keep-all' | 'auto-phrase' | 'break-word';
type WhiteSpace = 'normal' | 'pre' | 'nowrap' | 'pre-line' | 'pre-wrap';

type Unit = 'px' | 'em' | '%';
export type NumberWithUnit = `${number}${Unit}`;

export interface CommonBasicTextProps {
  size: number;
  pcSize?: number;
  colorStyle?: TextColorKeys;
  weight?: Weight;
  lineHeight?: number;
  letterSpacing?: NumberWithUnit;
  fontFamily?: FontThemeStyle;
  textAlign?: TextAlign;
  wordBreak?: WordBreak;
  whiteSpace?: WhiteSpace;
  truncateLine?: number;
  fontStyle?: FontStyle;
}

const hasUnit = (value: NumberWithUnit, unit: Unit): boolean => {
  return new RegExp(`[0-9]${unit}$`).test(value);
};

const toNum = (value: NumberWithUnit, unit: Unit): number => {
  const raw = value.replace(new RegExp(`${unit}$`, ''), '');
  return Number(raw);
};

const calcLetterSpacing = (
  value: NumberWithUnit | undefined,
  size: number,
  isSP?: boolean,
): string => {
  if (!value) {
    return 'normal';
  } else if (hasUnit(value, 'px')) {
    const _px = toNum(value, 'px');
    return isSP ? pxVw.SP(_px) : px(_px);
  } else if (hasUnit(value, 'em')) {
    const _em = toNum(value, 'em');
    const emToPx = _em * size;
    return isSP ? pxVw.SP(emToPx) : px(emToPx);
  } else if (hasUnit(value, '%')) {
    const _percent = toNum(value, '%');
    const percentToPx = (_percent * size) / 100;
    return isSP ? pxVw.SP(percentToPx) : px(percentToPx);
  } else {
    return 'normal';
  }
};

export const commonBasicTextCss = ({
  size,
  pcSize = size,
  colorStyle = 'main',
  weight,
  lineHeight,
  letterSpacing,
  fontFamily = 'default',
  textAlign,
  wordBreak,
  whiteSpace,
  truncateLine = 0,
  fontStyle,
}: CommonBasicTextProps) => css`
  font-size: ${px(pcSize)};
  font-family: ${({ theme }) => fontFamilies[theme.font[fontFamily]]};
  font-weight: ${weight ?? 'normal'};
  font-style: ${fontStyle ?? 'normal'};
  word-break: ${wordBreak ?? 'inherit'};
  letter-spacing: ${calcLetterSpacing(letterSpacing, pcSize)};
  line-height: ${lineHeight ?? px(pcSize)};
  text-align: ${textAlign ?? 'inherit'};
  white-space: ${whiteSpace ?? 'inherit'};
  color: ${colors.text[colorStyle]};

  ${truncateLine > 0 &&
  css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: ${truncateLine};
    overflow: hidden;
    word-break: break-word;
  `}
  ${media.sp} {
    font-size: ${pxVw.SP(size)};
    line-height: ${lineHeight ?? pxVw.SP(size)};
    letter-spacing: ${calcLetterSpacing(letterSpacing, size, true)};

    .brpc {
      display: none;
    }
  }
`;
