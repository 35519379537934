export const useEnv = () => {
  const env = process.env.NEXT_PUBLIC_APP_ENV;

  const isProd = env === 'production';
  const isStaging = env === 'staging';
  const isDev = env === 'development';
  const isLocal = env === 'local';

  const canUseDevelopingFeature = isDev || isLocal;

  return { env, isProd, isStaging, isDev, isLocal, canUseDevelopingFeature };
};
