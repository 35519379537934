import type { ThemeStyle } from '~/styles/theme/type';

export const ja: ThemeStyle = {
  style: 'ja',
  date: {
    fullFormat: 'y/M/d',
    dateFormat: 'M/d',
  },
  text: {
    sizeAdjust: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    lineHeightFix: false,
    lineHeight: '1.7',
    letterSpacing: '0.03em',
    align: 'justify',
    vAlign: '0',
  },
  font: {
    default: 'notoSans',
    numeric: 'sen',
    sen: 'sen',
  },
};
