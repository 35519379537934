import { STORAGE_PREFIX } from '~/persistence/prefix';

export const clearAllPersist = () => {
  if (typeof window === 'undefined') return;
  const regexp = new RegExp('^' + STORAGE_PREFIX);
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i) ?? '';

    if (regexp.test(key)) {
      window.localStorage.removeItem(key);
    }
  }
};
