import { EventType } from '~/features/events/interfaces/enums';

export type BasicColor = (typeof BASIC_COLORS)[keyof typeof BASIC_COLORS];
export type TextColorKeys =
  | 'main'
  | 'inversed'
  | 'warn'
  | 'edit'
  | 'disabled'
  | 'palceholderGray'
  | 'textGray'
  | 'linkGray'
  | 'addBlue';
type BgColorKeys = 'main' | 'inversed';

const BASIC_COLORS = {
  snRed: '#E05E3E',
  snBlue: '#2A85DE',
  snYellow: '#F2C550',
  snGreen: '#398175',
  snSkyBlue: '#67ACD0',
  snPink: '#F4AEB5',
  snBeige: '#DCDEC3',
  snPaleGreen: '#73B997',
  snLightGray: '#F4F4F4',
  snPaleGreenLight: '#9DCEB6',
  snSkyBlueLight: '#CDE4EB',
  gray_1: '#DBDBDB',
  gray_2: '#C4C4C4',
  gray_3: '#A8A8A8',
  gray_4: '#808080',
  gray_5: '#666666',
  snPalePurple: '#CBC5EA',
  snNavy: '#073356',
  white: '#FFFFFF',
  black: '#000000',
  select_blue: '#3CFFF3',
  paleBlue: '#ACCFE3',
  unselect_lightblue: '#BFDAF5',
  textbox_lightblue: '#95C5DE',
  textbox_blue: '#2A85DE',
  add_blue: '#0F63F7',
  textbox_palepink: '#FBE8E2',
  asicsKidsNavy: '#001E62',
  none: 'none',
  transparent: 'transparent',
  thinWhite_1: 'rgba(255, 255, 255, 0.5)',
  thinWhite_2: 'rgba(255, 255, 255, 0.95)',
  thinBlack_1: 'rgba(0, 0, 0, 0.8)',
  thinBlack_2: 'rgba(0, 0, 0, 0.3)',
} as const;

const textColors: Record<TextColorKeys, BasicColor> = {
  main: BASIC_COLORS.asicsKidsNavy,
  inversed: BASIC_COLORS.white,
  warn: BASIC_COLORS.snRed,
  edit: BASIC_COLORS.snSkyBlue,
  disabled: BASIC_COLORS.gray_2,
  palceholderGray: BASIC_COLORS.gray_3,
  textGray: BASIC_COLORS.gray_4,
  linkGray: BASIC_COLORS.gray_5,
  addBlue: BASIC_COLORS.add_blue,
};

const bgColors: Record<BgColorKeys, BasicColor> = {
  main: BASIC_COLORS.white,
  inversed: BASIC_COLORS.black,
};

export const colors = {
  primary: BASIC_COLORS.asicsKidsNavy,
  text: textColors,
  bg: bgColors,
  //TODO: colors内の定義から外したい
  events: {
    eventColor: {
      [EventType.birthday]: BASIC_COLORS.snPink,
      [EventType.previous_measurement]: BASIC_COLORS.asicsKidsNavy,
      [EventType.next_measurement]: BASIC_COLORS.snYellow,
      [EventType.replacement]: BASIC_COLORS.snPaleGreenLight,
      [EventType.asics_event]: BASIC_COLORS.paleBlue,
    },
  },
  sole: {
    default: [BASIC_COLORS.snBlue, BASIC_COLORS.snSkyBlue, '#97CBE5'],
    children: [
      BASIC_COLORS.snPalePurple,
      BASIC_COLORS.snSkyBlue,
      BASIC_COLORS.snPink,
      BASIC_COLORS.snGreen,
      BASIC_COLORS.snYellow,
      BASIC_COLORS.snBlue,
    ],
  },
  ...BASIC_COLORS,
};
