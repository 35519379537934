export const DeviceTypes = <const>{
  SP: 'SP',
  Tablet: 'Tablet',
  PC: 'PC',
};

export const FacingModes = <const>{
  User: 'user',
  Environment: 'environment',
};

export type DeviceTypes = (typeof DeviceTypes)[keyof typeof DeviceTypes];
export type FacingModes = (typeof FacingModes)[keyof typeof FacingModes];
