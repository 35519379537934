import { useSetRecoilState } from 'recoil';
import type { ThemeType } from '~/interfaces/enums/theme';
import { themeAtom } from '~/recoil/theme/atom';

export const useThemeAction = () => {
  const useSetState = useSetRecoilState(themeAtom);

  const useSet = (theme: ThemeType) => {
    useSetState(theme);
  };

  return { setTheme: useSet };
};
