import axios, { isCancel } from 'axios';
import type { AxiosResponse, AxiosRequestTransformer } from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import type { rest } from 'msw';
import { CookieJar } from 'tough-cookie';
import type { CancelableAxiosError } from '~/interfaces/api';

const jar = new CookieJar();

const axiosInstance = wrapper(
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    jar,
    withCredentials: true,
  }),
);

axiosInstance.interceptors.request.use((req) => {
  if (process.env.NEXT_PUBLIC_API_USE_MOCK_RESPONSES === 'true') {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-console
      console.debug(req);
    }
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (err: CancelableAxiosError) => {
    err.isCanceled = isCancel(err);
    return Promise.reject(err);
  },
);

interface Params<T> {
  url: `/${string}`;
  method: keyof typeof rest;
  headers?: { 'Content-Type': string };
  transformRequest?: AxiosRequestTransformer;
  contents?: (res: AxiosResponse<T>) => T;
}

export const sendRequest =
  <P, R>({
    url,
    method,
    headers = { 'Content-Type': 'application/json' },
    transformRequest,
    contents,
  }: Params<R>) =>
  async (data: P): Promise<R> => {
    const sendData: { data: P } | { params: P } =
      method === 'get' ? { params: data } : { data: data };

    const res = await axiosInstance.request<P, AxiosResponse<R>>({
      url,
      method,
      ...sendData,
      headers,
      ...(transformRequest && { transformRequest: [transformRequest] }),
    });

    return contents ? contents(res) : res.data;
  };
