import styled, { keyframes } from 'styled-components';
import { pxVw } from '~/styles/ts/functions';

const BabbleAnimation = keyframes`
  30% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.5);
  }
`;

const Bullets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bullet = styled.div<{ color: string; delay: number; size: number }>`
  margin: 0 ${pxVw.SP(8)};
  width: ${({ size }) => pxVw.SP(size)};
  height: ${({ size }) => pxVw.SP(size)};
  border-radius: 100%;
  background-color: ${({ color }) => color};
  animation-fill-mode: both;
  animation: 0.7s ease infinite ${BabbleAnimation};
  animation-delay: ${({ delay }) => `${delay}s`};
`;

interface Props {
  color: string;
  size?: number;
}

export const Loading = ({ color, size = 12 }: Props) => {
  return (
    <Bullets>
      <Bullet color={color} delay={0} size={size} />
      <Bullet color={color} delay={0.2} size={size} />
      <Bullet color={color} delay={0.4} size={size} />
    </Bullets>
  );
};
