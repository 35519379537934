import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';
import { colors } from '~/styles/ts/colors';

type LoadingContextType = {
  isLoading: boolean;
  color: string;
  openLoading: (color?: string) => void;
  closeLoading: () => void;
};

const defaultValues: LoadingContextType = {
  isLoading: false,
  color: colors.snBlue,
  openLoading: () => {
    // 何もしない
  },
  closeLoading: () => {
    // 何もしない
  },
};

const LoadingContext = createContext(defaultValues);

const LoadingContextProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(defaultValues.isLoading);
  const [color, setColor] = useState<string>(defaultValues.color);

  const openLoading = (color = defaultValues.color) => {
    setColor(color);
    setIsLoading(true);
  };

  const closeLoading = () => {
    setIsLoading(false);
  };

  const values: LoadingContextType = {
    isLoading,
    color,
    openLoading,
    closeLoading,
  };

  return <LoadingContext.Provider value={values}>{children}</LoadingContext.Provider>;
};

const useLoadingContext = () => useContext(LoadingContext);

export { useLoadingContext, LoadingContextProvider };
