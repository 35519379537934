import type { RegionCode } from '~/api/aspida/@types';
import type { AssistantIntervalItem } from '~/features/assistant/hooks/useAssistant';
import type { PersistenceData } from '~/features/measure/interfaces/models';
import type { MFIDParams } from '~/features/mobileFootID';
import type { UnixTime } from '~/interfaces/common';
import { PersistenceKeys } from '~/persistence/keys';
import { Persistence } from '~/persistence/persistence';

export const artworkPersistences = {
  HasSubmitted: new Persistence<boolean>(PersistenceKeys.artwork.hasSubmitted),
  IsAccessed: new Persistence<boolean>(PersistenceKeys.artwork.isAccessed),
};

export const entryPersistences = {
  CampaignTag: new Persistence<string>(PersistenceKeys.entry.campaignTag),
  Campaigns: new Persistence<{ [key: string]: string }>(PersistenceKeys.entry.campaigns),
  PostedCampaigns: new Persistence<{ [key: string]: boolean }>(
    PersistenceKeys.entry.postedCampaigns,
  ),
};

export const eventsPersistences = {
  ReadDateEvents: new Persistence<UnixTime>(PersistenceKeys.events.readDateEvents),
};

export const knowledgePersistences = {
  ReadDatePersonal: new Persistence<UnixTime>(PersistenceKeys.knowledge.readDatePersonal),
  ReadDateTrivia: new Persistence<UnixTime>(PersistenceKeys.knowledge.readDateTrivia),
};

export const measurePersistences = {
  Suspended: new Persistence<PersistenceData>(PersistenceKeys.measure.suspended),
};

export const mfscanPersistences = {
  Data: new Persistence<Record<string, MFIDParams>>(PersistenceKeys.mfscan.data),
};

export const regionPersistences = {
  Data: new Persistence<RegionCode>(PersistenceKeys.region.data),
};

export const assistantPersistences = {
  Interval: new Persistence<AssistantIntervalItem[]>(PersistenceKeys.assistant.interval),
  ShowFromMeasure: new Persistence<boolean>(PersistenceKeys.assistant.showFromMeasure),
  ShowFromCloakroom: new Persistence<boolean>(PersistenceKeys.assistant.showFromCloakroom),
};
