export const EventType = <const>{
  birthday: 'birthday',
  previous_measurement: 'previous_measurement',
  next_measurement: 'next_measurement',
  replacement: 'replacement',
  asics_event: 'asics_event',
};

export const EventsMode = <const>{
  calendar: 'calendar',
  list: 'list',
};

export type EventType = (typeof EventType)[keyof typeof EventType];
export type EventsMode = (typeof EventsMode)[keyof typeof EventsMode];
