import type { ThemeStyle } from '~/styles/theme/type';

export const en: ThemeStyle = {
  style: 'en',
  date: {
    fullFormat: 'y/M/d',
    dateFormat: 'M/d',
  },
  text: {
    sizeAdjust: [0, 4, 6, 8, 10, 12, 14, 16, 18, 20],
    lineHeightFix: true,
    lineHeight: '1.4',
    letterSpacing: '0',
    align: 'left',
    vAlign: '-6px',
  },
  font: {
    default: 'asics',
    numeric: 'asics',
    sen: 'sen',
  },
};
