import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGetRegion } from '~/api/hooks/useGetRegion';
import { regionService } from '~/features/region/regionService';
import { regionPersistences } from '~/persistence';

export const useCustomRouting = () => {
  const { data: regionData } = useGetRegion();
  const router = useRouter();
  const region = regionPersistences.Data;

  const createUrl = (region: string, locale: string, asPath: string) => {
    const pathname = asPath === '/' ? '' : asPath;
    return `/${region}/${locale}${pathname}`;
  };

  useEffect(() => {
    if (regionService.hasRegionPath(window.location.pathname)) {
      const regionCodeFromPath = regionService.getRegionCodeFromPathName(router.asPath);

      if (regionCodeFromPath) {
        region.set(regionCodeFromPath);
      }
      return;
    }

    if (region.value) {
      const url = createUrl(region.value, router.locale ?? '', router.asPath);
      setTimeout(() => {
        window.history.replaceState(null, '', url);
      }, 100);

      return;
    }

    if (!regionData) return;

    const apiRegion = regionService.normalizeRegionCode(regionData.region_code);

    region.set(apiRegion);
    const url = createUrl(apiRegion, router.locale ?? '', router.asPath);
    window.history.replaceState(null, '', url);
  }, [regionData, region, router]);
};
