export const PersistenceKeys = <const>{
  artwork: {
    hasSubmitted: 'artwork.hasSubmitted',
    isAccessed: 'artwork.isAccessed',
  },
  entry: {
    campaignTag: 'entry.campaignTag',
    campaigns: 'entry.campaigns',
    postedCampaigns: 'entry.postedCampaigns',
  },
  events: {
    readDateEvents: 'events.read_date',
  },
  knowledge: {
    readDatePersonal: 'knowledge.read_date.personal',
    readDateTrivia: 'knowledge.read_date.trivia',
  },
  measure: {
    suspended: 'measure.suspended',
  },
  mfscan: {
    data: 'mfscan.data',
  },
  region: {
    data: 'region',
  },
  assistant: {
    interval: 'assistant.interval',
    showFromMeasure: 'assistant.showFromMeasure',
    showFromCloakroom: 'assistant.showFromCloakroom',
  },
};

export type PersistenceKeys = (typeof PersistenceKeys)[keyof typeof PersistenceKeys];
