import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

type ModalElement = () => JSX.Element;

interface ContextType {
  isOpen: boolean;
  elementQueue: ModalElement[];
  openModal: (newElement: ModalElement) => void;
  openModalInterrupt: (newElement: ModalElement) => void;
  closeModal: () => void;
}

const defaultValues: ContextType = {
  isOpen: false,
  elementQueue: [],
  openModal: () => undefined,
  openModalInterrupt: () => undefined,
  closeModal: () => undefined,
};

const ModalContext = createContext(defaultValues);

const useContext = (): ContextType => {
  const router = useRouter();
  const [elementQueue, setElementQueue] = useState<ModalElement[]>([]);
  const [isOpen, setIsOpen] = useState<ContextType['isOpen']>(defaultValues.isOpen);
  const [currentPath, setCurrentPath] = useState<string>('');

  // TODO: 仕様 #2875
  const openModal = useCallback((newElement: ModalElement) => {
    setElementQueue((v) => [...v, newElement]);
  }, []);

  const openModalInterrupt = useCallback((newElement: ModalElement) => {
    setElementQueue((v) => {
      v.splice(1, 0, newElement);
      return v;
    });
  }, []);

  const closeModal = useCallback(() => {
    setElementQueue((v) => v.slice(1));
  }, []);

  const allClearModal = useCallback(() => {
    setElementQueue([]);
  }, []);

  const tryCloseModal = useCallback(
    (path: string) => {
      if (path !== currentPath && !path.includes('prepare')) {
        allClearModal();
      }
      setCurrentPath(path);
    },
    [currentPath, allClearModal],
  );

  useEffect(() => {
    setIsOpen(!!elementQueue[0]);
  }, [elementQueue]);

  useEffect(() => {
    setCurrentPath(router.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!router) return;
    router.events.on('routeChangeStart', tryCloseModal);
    return () => {
      router.events.off('routeChangeStart', tryCloseModal);
    };
  }, [router, tryCloseModal]);

  return {
    isOpen,
    elementQueue,
    openModal,
    openModalInterrupt,
    closeModal,
  };
};

const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  return <ModalContext.Provider value={useContext()}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalContextProvider };
