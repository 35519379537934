import type { ImageProps } from 'next/image';
import Image from 'next/image';
import styles from './CommonImage.module.scss';
import { px, pxVw } from '~/styles/ts/functions';
import { media } from '~/styles/ts/media';

interface Props {
  src: string;
  width: number;
  height: number;
  spWidth?: number;
  spHeight?: number;
  alt?: string;
  rotate?: number;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  objectPosition?: string;
  crossOrigin?: ImageProps['crossOrigin'];
  className?: string;
}

export const CommonImage = ({
  src,
  width,
  height,
  spWidth = width,
  spHeight = height,
  alt = '',
  rotate,
  objectFit,
  objectPosition = '50% 50%',
  crossOrigin,
  className = '',
}: Props) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      style={
        media.ua().isSP
          ? {
              width: pxVw.SP(spWidth),
              height: pxVw.SP(spHeight),
              ...(rotate ? { transform: `rotate(${rotate}deg)` } : {}),
            }
          : {
              width: px(width),
              height: px(height),
              ...(rotate ? { transform: `rotate(${rotate}deg)` } : {}),
            }
      }
    >
      <Image
        src={src}
        alt={alt}
        style={{
          objectFit: `${objectFit ?? 'unset'}`,
          objectPosition: `${objectPosition ?? 'unset'}`,
        }}
        crossOrigin={crossOrigin}
        fill
      />
    </div>
  );
};
