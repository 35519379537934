import { UAParser } from 'ua-parser-js';

const checkSupports = (isFlag: boolean) =>
  isFlag ? '@supports (width: 1px)' : '@supports (false)';

const ua = () => {
  const parser = new UAParser();
  const device = parser.getDevice().type;

  return {
    isSP: device === 'mobile',
    isTablet: device === 'tablet',
    isPC: device === undefined,
  };
};

const sp = checkSupports(ua().isSP || ua().isTablet);
const pc = checkSupports(ua().isPC);

export const media = { sp, pc, ua, checkSupports };
